<template>
  <div class="main">
    <div class="header">
      <div class="searchIcon">
        <img src="../assets/logo.svg" class="searchImg">
      </div>
      <div class="searchBox">
        <input r="30,0,0,30" placeholder="输入关键字搜索" class="searchInput" @keyup.enter="search(keyWord)" v-model="keyWord">
        <button type="submit" r="0,30,30,0" class="searchSubmit" @click="search(keyWord)">开始搜索</button>
      </div>
      <div class="">
        <el-button class="button"  type="text" icon="el-icon-s-home" @click="link('/')">首页</el-button>
      </div>
    </div>
    <div class="docList">
      <div v-if="resultList.length === 0" style="margin: 20px 50px;color: #aaaaaa">暂无文章</div>
      <DocItem :data="item" v-for="(item,index) in resultList"></DocItem>
    </div>
  </div>
</template>

<script>
import DocItem from "@/components/DocList/docItem";
import axios from "axios";
export default {
  name: "Search",
  components:{
    DocItem,
  },
  data(){
    return {
      keyWord:'',
      resultList:[
        {
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },{
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },{
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },{
          title:"中国安能集团华东投资开发有限公司与青岛海丽雅集团有限公司签署战略合作协议",
          editor:'中国安能集团华东投资开发有限公司',
          views:123,
          pub_date:"2022-07-21",
          type:'公司动态',
        },
      ],
    }
  },
  mounted() {
    console.log(this.$route.query.q)
    this.keyWord = this.$route.query.q
    this.search(this.$route.query.q)
  },
  methods:{
    search(key){
      console.log(key);
      this.axios({
        url:'/api/document/user/article/search',
        method:'GET',
        params:{
          q:key
        }
      }).then((res)=>{
        this.resultList = res.data;
        console.log('articles', this.resultList);
      })
    },
    link(url){
      window.location.href = url;
    },
  }
}
</script>

<style scoped>
.main {
  margin-left: 80px;
}
.header{
  /*display: flex;*/
  /*position: fixed;*/
  float: top;
  top: 0;
  width: 100%;
  min-width: 900px;
  height: 78px;
  z-index: 99;
  /*background: #42b983;*/
}
.button{
  margin: 15px auto;
  opacity: 1;
  color: rgba(0, 0, 0);
  font-size: 15px;
}
.button:hover {
  opacity: 0.6;
}
.searchIcon{
  display: flex;
  float: left;
  background: rgb(255, 255, 255);
  width: 80px;
  height: 100%;
}
.searchImg{
  margin: auto;
  /*margin-top: 19px;*/
  /*font-size: 30px;*/
  /*color: #333333;*/
  width: 44px;
  /*height: 30px;*/
}
.searchBox {
  /*margin: auto;*/
  margin-top: 19px;
  display: flex;
  float: left;
  width: 80%;
  height: 45px;
  /*flex-direction: row;*/
  /*flex: 1 1 0%;*/
  background: rgb(255, 255, 255);
  /*box-sizing: border-box;*/
}
.searchInput {
  width: 40%;
  border: 1px solid rgba(0, 63, 119, 0.1);
  outline: none;
  /*white-space: nowrap;*/
  /*vertical-align: middle;*/
  background: rgb(247, 248, 249);
  padding: 6px 15px;
  font-size: 12px;
  /*border-radius: 30px 0 0 30px;*/
  color: rgb(44, 50, 63);
  line-height: 1.42857;
  /*-webkit-writing-mode: horizontal-tb !important;*/
}
.searchSubmit {
  transition: all 0.5s ease 0s;
  outline: none;
  width: 120px;
  /*white-space: nowrap;*/
  /*vertical-align: middle;*/
  cursor: pointer;
  background: rgb(0, 63, 119);
  text-align: center;
  touch-action: manipulation;
  /*appearance: button;*/
  padding: 6px 15px;
  font-size: 12px;
  border: 1px solid rgb(0, 63, 119);
  /*border-radius: 0 30px 30px 0;*/
  color: rgb(255, 255, 255);
  line-height: 1.42857;
  /*-webkit-writing-mode: horizontal-tb !important;*/
}
.docList{
  max-width: 640px;
  margin: 10px 20px;
}
</style>