<template>
<div class="container">

  <img v-if="data.cover" :src="data.cover" style="max-width: 200px;padding: 10px;object-fit: cover"/>
  <div class="container_right" :style="data.cover?'width: calc(100% - 200px);':'width: 100%'">
    <div class="title" @click="click">{{data.title}}</div>
    <div class="separation"></div>
    <div class="detail_container">
      <span class="detail">来源：{{data.editor}}</span>
<!--      <span class="detail">浏览:{{data.views}}</span>-->
      <span class="detail">{{data.pub_date.slice(0,10)}}</span>
    </div>
    <div class="detail_container" style="margin-top: 10px;">
      <span class="detail">所属类别:{{data.category.title}}</span>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "docItem",
  props:{
    data:{}
  },
  methods:{
    click(){
      if(this.data.external_link){
        window.open(this.data.external_link)
      }else{
        window.open('/document?id='+this.data.articleId)
      }
    }
  }
}
</script>

<style scoped>
.container{
  display: flex;
  border-bottom: 1px solid rgba(229, 229, 229, 0.7);
  width: 100%;
}
.container_right{
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
}
.title{
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title:hover{
  text-decoration: underline;
}
.separation{
  height: 1px;
  border-bottom: 1px dashed rgba(229, 229, 229, 0.5);
  margin-top: 5px;
  box-sizing: border-box;
}
.detail_container{
  text-align: left;
}
.detail{
  padding-right: 15px;
  font-size: 12px;
  line-height: 28px;
  opacity: 0.8;
}
</style>